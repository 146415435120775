export class Application {
  constructor(data) {
    this.data = data
    this.documentID = data.documentID
  }

  collection() {
    return "applications"
  }

  setDocumentId(id) {
    this.documentID = id
  }

  getSubdocuments() {
    return []
  }

  toFirebaseDocument() {
    return {
      name: this.data.name,
      documentID: this.documentID,
      cars: this.data.cars,
      email: this.data.email,
      photoUrl: this.data.photoUrl,
      reason: this.data.reason,
      approvesArt: this.data.approvesArt,
      crew: this.data.crew,
      buddy: this.data.buddy,
      recaptchaToken: this.data.recaptchaToken,
    }
  }
}
