import React from "react"
import { FieldArray, Formik, useFormikContext } from "formik"
import * as Yup from "yup"
import {
  Col,
  Form,
  Row,
  Container,
  Button,
  Spinner,
  InputGroup,
} from "react-bootstrap"
import ImageUpload from "../../components/common/ImageUpload"
import { Application } from "../../models/application.model"
import { useFileUpload } from "../../hooks/useFileUpload"
import { useDb } from "../../hooks/useDb"
import Helmet from "react-helmet"
import { useLocation } from "@gatsbyjs/reach-router"
import querystring from "query-string"
import ReCAPTCHA from "react-google-recaptcha"

const FormCookieName = "application_form_submit"

const getCookieName = (event) => `${event}_${FormCookieName}`

// Thanks: https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
function setCookie(event, value, days) {
  if (typeof document !== "undefined") {
    var expires = ""
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }
    document.cookie =
      getCookieName(event) + "=" + (value || "") + expires + "; path=/"
  }
}

function getCookie(event) {
  if (typeof document !== "undefined") {
    var nameEQ = getCookieName(event) + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }
}

const FormTextField = ({ label, placeholder, name, fieldName, ...rest }) => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext()

  if (!(fieldName in values)) {
    throw new Error(
      `Attempting to create a field for a form field that does not exist : ${fieldName}`
    )
  }

  return (
    <div {...rest}>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        name={name}
        id={name}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={!!errors[fieldName] && !!touched[fieldName]}
        className="bg-white"
      />
      <Form.Control.Feedback type="invalid">
        {errors[fieldName]}
      </Form.Control.Feedback>
    </div>
  )
}

const FormTextBoxField = ({
  label,
  placeholder,
  fieldName,
  maxLength = 500,
  resize = "none",
  ...rest
}) => {
  const { values, errors, touched, handleBlur, handleChange } =
    useFormikContext()

  if (!(fieldName in values)) {
    throw new Error(
      `Attempting to create a field for a form field that does not exist : ${fieldName}`
    )
  }

  return (
    <div {...rest}>
      <Form.Label htmlFor={fieldName}>{label}</Form.Label>
      <Form.Control
        style={{
          height: "6rem",
          minHeight: "6rem",
          resize: resize,
        }}
        className="bg-white"
        type="text"
        id={fieldName}
        as="textarea"
        maxLength={maxLength}
        placeholder={placeholder}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={!!errors[fieldName] && !!touched[fieldName]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[fieldName]}
      </Form.Control.Feedback>
    </div>
  )
}

const SubmissionDeadline = new Date("2022-06-28T09:00:00Z") // Midnight Central Time on June 27th

const OvercrestApplication = () => {
  const location = useLocation()
  const noHeader = React.useMemo(
    () => querystring.parse(location.search)["noHeader"],
    [location]
  )
  const { uploadFile } = useFileUpload()
  const { generateDocId, saveModel, getDownloadUrl } = useDb()
  const [formSubmitted, setFormSubmitted] = React.useState(
    getCookie("overcrest-rally-2022") !== null
  )
  const currentDate = new Date()

  const submitApplication = React.useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true)
      const application = new Application({
        name: values.name,
        email: values.email,
        cars: values.cars.map((c) => c.trim()).filter((c) => !!c),
        reason: values.reason,
        approvesArt: values.artApproval,
        crew: values.crew,
        buddy: values.buddy,
        recaptchaToken: values.recaptcha,
      })

      const documentId = generateDocId(application.collection())
      const imagePath = `applications/${documentId}/${values.carPhotoFile["name"]}`
      const photoUrl = await uploadFile(values.carPhotoFile, imagePath)
      application.data.photoUrl = await getDownloadUrl(photoUrl)
      application.documentID = documentId

      await saveModel(application)
      setFormSubmitted(true)
      setCookie("overcrest-rally-2022", new Date().toISOString(), 30)
      setSubmitting(false)
    },
    [uploadFile, generateDocId, saveModel, setFormSubmitted, getDownloadUrl]
  )

  if (formSubmitted) {
    return (
      <Container
        className="mt-2 bg-white overcrest-form"
        style={{ color: "rgba(18, 18, 18, 0.75)" }}
      >
        <Helmet>
          <body className="bg-white" />
        </Helmet>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h1 className="text-center">Thank you!</h1>
          <img
            style={{ width: "60%" }}
            src="https://firebasestorage.googleapis.com/v0/b/rallista-ios.appspot.com/o/logos%2Fovercrest%2FOC%20Rally.png?alt=media&token=02fc6112-c4e3-404d-b917-84482bc8b367"
            alt="Overcrest Rally logo"
          />
          <h1 className="text-center">We have received your application</h1>
        </div>
      </Container>
    )
  }

  if (currentDate >= SubmissionDeadline) {
    return (
      <Container
        className="mt-2 bg-white overcrest-form"
        style={{ color: "rgba(18, 18, 18, 0.75)" }}
      >
        <Helmet>
          <body className="bg-white" />
        </Helmet>
        <h1 className="text-center">
          Applications for the 2022 Overcrest Rally have closed.
        </h1>
      </Container>
    )
  }

  return (
    <Container className="mt-2 bg-white overcrest-form">
      <Helmet>
        <body className="bg-white" />
      </Helmet>
      {noHeader ? null : (
        <h1 className="text-center">Apply for the 2022 Overcrest Rally</h1>
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          cars: [""],
          carPhotoFile: "",
          reason: "",
          artApproval: false,
          crew: "",
          buddy: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(100, "Cannot be longer than 100 characters")
            .label("Name")
            .required("Name is required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("An email address is required"),
          cars: Yup.array()
            .of(
              Yup.string().max(
                75,
                "Please keep your car description below 75 characters"
              )
            )
            .max(3)
            .min(1)
            .required("Please supply the year, make, and model of your car"),
          carPhotoFile: Yup.mixed()
            .test("file", "Improper file", (file) => true)
            .required("An image of your car is required"),
          reason: Yup.string().required(
            "Please tell us the story of why you want to come on the rally"
          ),
          crew: Yup.string(),
          buddy: Yup.string(),
          artApproval: Yup.bool(),
          recaptcha: Yup.string().required(),
        })}
        onSubmit={submitApplication}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          dirty,
          isValid,
          handleBlur,
        }) => (
          <Form
            data-testid="application-form"
            onSubmit={handleSubmit}
            className="h-100 d-flex flex-column gap-1 mt-4"
          >
            <Row>
              <Col style={{ minHeight: 300 }}>
                <ImageUpload
                  emptyImageMessage={"Upload a photo of your car"}
                  onFileSelected={(file) => {
                    setFieldValue("carPhotoFile", file)
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormTextField
                  label="Name*"
                  placeholder="First and last name"
                  name="name"
                  fieldName="name"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FieldArray
                  name="cars"
                  render={(arrayHelpers) => {
                    const shouldDisplayAddButton = (index) =>
                      index === values.cars.length - 1 && values.cars.length < 3

                    const isInvalid = (index) => {
                      return (
                        errors.cars &&
                        errors.cars.length !== undefined &&
                        errors.cars[index] !== undefined &&
                        touched.cars &&
                        touched.cars.length !== undefined &&
                        touched.cars[index] !== undefined
                      )
                    }
                    return (
                      <div>
                        <Form.Label>Car*</Form.Label>
                        {values.cars && values.cars.length > 0
                          ? values.cars.map((car, index) => (
                            <div
                              key={index}
                              className={index > 0 ? "mt-2" : ""}
                            >
                              <InputGroup>
                                <Form.Control
                                  name={`cars.${index}`}
                                  type="text"
                                  placeholder={"Year, make, and model"}
                                  id={`cars.${index}`}
                                  value={car}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={isInvalid(index)}
                                  className="bg-white"
                                />
                                {index > 0 ? (
                                  <InputGroup.Append>
                                    <Button
                                      variant="outline-danger"
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }
                                    >
                                        Remove
                                    </Button>
                                  </InputGroup.Append>
                                ) : null}
                              </InputGroup>
                              <Form.Control.Feedback type="invalid">
                                {errors.cars &&
                                  errors.cars.length !== undefined
                                  ? errors.cars[index]
                                  : ""}
                              </Form.Control.Feedback>
                              {shouldDisplayAddButton(index) ? (
                                <Button
                                  className="mt-2"
                                  variant="outline-secondary"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                    + Add an alternative car
                                </Button>
                              ) : null}
                            </div>
                          ))
                          : null}
                      </div>
                    )
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormTextField
                  label="Email*"
                  placeholder="Email"
                  name="email"
                  fieldName="email"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormTextBoxField
                  label="Why do you want to come on the rally?*"
                  fieldName="reason"
                  maxLength={4000}
                  placeholder="Tell us a story"
                  resize="vertical"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormTextBoxField
                  label={`Do you have friends or a "crew" that is also applying? If so, list them here:`}
                  fieldName="crew"
                  placeholder="List your friends"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormTextField
                  label="In the instance we are not able to accept your car, is there another applicant that you could ride with?"
                  name="buddy"
                  fieldName="buddy"
                  placeholder="Name of other applicant"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Check
                  id="artApproval"
                  type="checkbox"
                  label="By checking this box, I agree to allow Overcrest to use the likeness of my car for promotional purposes."
                  custom
                  value={values.artApproval.toString()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center mb-2">
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                id="recaptcha"
                onChange={(data) => {
                  setFieldValue("recaptcha", data)
                }}
              />
            </Row>
            <Row className="mt-4 justify-content-center mb-2">
              <Button
                type="submit"
                variant="primary"
                className="application-submit px-4"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    data-testid="loading-spinner"
                    aria-hidden="true"
                    className="mx-4 mb-1"
                  />
                ) : (
                  "Submit Application"
                )}
              </Button>
            </Row>
            <Row>
              <Col className="text-center">
                <a
                  href="https://rallista.app"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  <span className="font-italic" style={{ fontSize: 12 }}>
                    powered by{" "}
                  </span>
                  <img
                    className="pb-2"
                    style={{ height: "100%", width: "4rem" }}
                    src="https://rallista.app/static/rallista-dk-87a315774f7ad8a7ce3d7196ce117ef2.png"
                    alt="rallista logo"
                  />
                </a>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default OvercrestApplication
